import { Component, OnInit, Renderer2, Input, EventEmitter, Output, HostListener  } from '@angular/core';
import { sessionStorage } from '../localstorage.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../dialog.component';
import { DatabaseService } from 'src/_services/DatabaseService';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ProgressService } from '../progress.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
  
})
export class HeaderComponent implements OnInit {
  searchData: any = {};
  login_data: any = {};
  dataList: any = [];
  downloaderActive$: any = false ;
  @Input() dataToReceive: any;
  @Output() itemClicked = new EventEmitter<void>();
  downloadPercent$ = this.progressService.getDownloadProgress();
  totalCount = this.progressService.getTotalCount();
  remainingCount = this.progressService.getRemainingCount();
  constructor(private renderer: Renderer2, public session: sessionStorage, public toastCtrl: ToastrManager, public serve: DatabaseService, public router: Router, public dialog: DialogComponent, private progressService: ProgressService) {
    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value;
    this.login_data = this.login_data.data;
  }
  
  ngOnInit() {
    this.progressService.getDownloaderActive().subscribe(downloaderActive => {
      this.downloaderActive$ = downloaderActive
    });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Check for F5, Ctrl + R, Cmd + R, Ctrl + Shift + R, Cmd + Shift + R
    if (event.key === 'F5' || 
        ((event.ctrlKey || event.metaKey) && event.key === 'r') || 
        ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'R')) {
      if (this.downloaderActive$) {
        event.preventDefault(); // Prevent the default action (refresh)
        alert('Download in progress!'); // Show your alert
      }
    }
  }
  
  colorMode: boolean = false;
  nightMode() {
    this.colorMode = !this.colorMode;
    if (this.colorMode) {
      this.renderer.addClass(document.body, 'dark-mode');
    }
    else {
      this.renderer.removeClass(document.body, 'dark-mode');
    }
  }
  
  toggle: boolean = false;
  toggleNav() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.renderer.addClass(document.body, 'active');
    }
    else {
      this.renderer.removeClass(document.body, 'active');
    }
  }
  
  filter_dr(search) {
    if (search.length > 3) {
      this.serve.post_rqst({ 'search': search }, "Master/masterSearch").subscribe((result => {
        if (result['statusCode'] == 200) {
          this.dataList = result['result'];
        }
        else {
          this.toastCtrl.errorToastr(result['statusMsg']);
        }
      }))
      
    }
  }
  
  
  id: any
  type: any
  dr_type: any
  gotodetail(data) {
    this.id = data.id
    this.type = data.module_name;
    this.dr_type = data.type
    if (this.type == 'Channel Partner' || this.type == 'Dealer' || this.type == 'Direct Dealers') {
      this.router.navigate(['/distribution-list/' + data.type + '/' + data.module_name + '/distribution-detail/' + this.id+'/'+'Profile', { queryParams: { 'state': data.state, 'id': data.id, 'type': data.type } }])
    }
    else if(this.type == 'Customer' || this.type == 'Plumber') {
      this.router.navigate(['/influencer/' + data.type + '/' + data.module_name + '/influencer-detail/' + this.id + '/' + data.type])
    } 
    else if(this.type == '') {
      this.router.navigate(['/sale-user-list/sale-user-detail/'+data.id])
    } 
    // else {
    //   this.router.navigate(['/sale-user-list/sale-user-detail/'+data.id]);
    // }
    this.searchData = {}
    this.dataList = [];
  }
  
  
  
  
  
  logout() {
    this.dialog.confirm("Logout").then((result) => {
      if (result) {
        this.session.LogOutSession();
        this.serve.datauser = {};
        this.router.navigate(['']);
      }
    });
    
  }
}
