import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import * as Highcharts from 'highcharts/highmaps';

import { MatSliderChange } from '@angular/material/slider';
import * as moment from 'moment';
declare const L: any
import { CheckinViewComponent } from 'src/app/checkin-view/checkin-view.component';
import { MatDialog } from '@angular/material';
var playback;
var control;
@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html'
})
export class TrackerComponent implements OnInit {
  @ViewChild('mapElement') mapElement: ElementRef;
  tabType: any = 'Live';
  payload: any = {};
  filter: any = {};
  datanotfound: boolean = false;
  selectedDate: any;
  empData: any = {};
  Checkincount: any
  mapPlaybackControl: any
  checkin_data: any = [];
  skLoading: boolean = false
  mapInitialized: boolean = false
  battery: any = [];
  Attendancedata: any = []
  CheckinData: any = []
  batteryTime: any = [];
  marker1: any;
  totalDistanceSummary: any
  map: any;
  route: any;
  batteryChart: any;
  pathCoords: any = [];
  latestLocation: any = {}
  btnValue: boolean = true
  sliderValue = 0;
  animationInterval: any;
  mapVisible: boolean = true;
  PlayBackStarted: boolean = false;
  today_date: any = new Date();
  waypoints = [];
  location: any = [];
  origin: any;
  destination: any;
  lat: any;
  lng: any;
  padding0: any;
  interval: any;
  index = 0
  marker: any;
  myMap: any;
  locationMarkers: any = [];
  @Input() dataToReceive: any;
  location_timeanddate: any;
  Locationtype: any;
  distance: any;
  Totaldistance: any;
  Trackerlat: any;
  Trackerlng: any;


  constructor(public router: ActivatedRoute, public dialog2: MatDialog, public service: DatabaseService, public toast: ToastrManager, public rout: Router) {
    if (this.myMap) {
      this.myMap.off(); // Remove the existing map if it exists
      this.myMap.remove(); // Remove the existing map if it exists
    }
  }



  AttendanceSummary() {

    let paylod = { 'start_date': this.filter.date, 'user_id': this.payload.user_id };


    this.service.post_rqst(paylod, "Location/attendanceSummary").subscribe((result => {
      if (result['statusCode'] == 200) {
        this.Attendancedata = result['data'];
        this.Checkincount = result['checkinCount'];
        this.totalDistanceSummary = result['total_distance'];
        if (this.Attendancedata.length == 0) {
          this.datanotfound = true
        } else {
          this.datanotfound = false
        }

      } else {
        this.toast.errorToastr(result['statusMsg']);
      }
    }))

  }


  ngOnInit(): void {
    if (this.dataToReceive != undefined) {
      this.padding0 = this.dataToReceive.padding0;
      this.payload = { 'start_date': this.dataToReceive.start_date, 'user_id': this.dataToReceive.user_id };
      this.getDetails();

    } else {
      this.router.params.subscribe(params => {
        this.payload = this.router.queryParams['_value'];
        if (this.payload) {
          this.selectedDate = this.filter.date = this.payload.start_date;

          this.getDetails();
        }
      });
    }

    // setTimeout(() => {
    //   this.initializeMap();
    // }, 700);
  }




  defaultCoordinates: any = {}
  getDetails() {

    this.skLoading = true;
    let header
    if (this.filter.date) {
      header = { 'start_date': this.filter.date, 'user_id': this.payload.user_id }
    }
    else {
      header = this.payload;
    }
    this.service.post_rqst(header, "Location/getLatestGeoLocation")
      .subscribe((result => {
        if (result['statusCode'] == 200) {
          this.empData = result['user_data'];
          this.latestLocation = result['latest_location'];
          this.defaultCoordinates = { lat: result['latest_location']['lat'], lng: result['latest_location']['lng'] };
          this.pathCoords = result['data'];
          this.location = result['data'];
          this.skLoading = false;

          this.locationMarkers = this.location;
          if (this.locationMarkers.length && this.tabType != 'Battery' && this.tabType != 'Attendance Summary') {
            if (this.mapPlaybackControl) {
              this.mapPlaybackControl = '';
            }
            this.initializeMap();
          }

        } else {
          this.toast.errorToastr(result['statusMsg'])
          this.skLoading = false;
        }
      }))
  }



  initializeMap(): void {
    if (this.myMap) {
      this.myMap.off(); // Remove the existing map if it exists
      this.myMap.remove(); // Remove the existing map if it exists
    }

    if (this.tabType == 'Play Back') {
      if (playback) {
        $('.lp').remove();
        control.playback.destroy()
      }
      this.myMap = L.map('map').setView([this.locationMarkers[0].lat, this.locationMarkers[0].lng], 16);
      var OSM = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 22,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
      var googleHybrid = L.tileLayer('http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      var googleStreets = L.tileLayer('http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      googleStreets.addTo(this.myMap);
      var Dark = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 22
      });
      const markerClusterGroup = L.markerClusterGroup();
      const polylinePoints = this.locationMarkers
      polylinePoints.forEach((point, index) => {
        const marker = L.marker([point.lat, point.lng]).addTo(this.myMap);
        if (point.type == 'Checkin') {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/checkin.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
          marker.bindPopup(`<p><strong><span style="color: blue">Checkin</span></strong><br /><strong>Customer : </strong> ${point.dr_name}<br /><strong>Address : </strong> ${point.address}</p>`);
        }
        else if (point.type == 'Checkout') {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/checkout.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
          marker.bindPopup(`<p><strong><span style="color: blue">Checkout</span></strong><br /><strong>Customer : </strong> ${point.dr_name}<br /><strong>Address : </strong> ${point.address}</p>`);
        }
        else if (point.type == 'Attendence Start') {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/start_point.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
          marker.bindPopup('Address : ' + point.address)
        }
        else if (point.type == 'Attendence Stop') {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/end_point.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
          marker.bindPopup('Address : ' + point.address)
        }
        else if (point.type == 'Checkout') {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/bg_location.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
        }
        else {
          marker.setIcon(L.icon({
            iconUrl: './assets/location/bg_location.png',
            iconSize: [0, 0],
            iconAnchor: [16, 32],
            riseOnHover: true,
          }));
        }
        markerClusterGroup.addLayer(marker);
      });
      this.myMap.addLayer(markerClusterGroup);

      var baseLayers = {
        "Streets": googleStreets,
        "OpenStreetMap": OSM,
        "Hybrid": googleHybrid,
        "Dark": Dark
      };
      L.control.layers(baseLayers).addTo(this.myMap);
      const waypoints = polylinePoints.map(point => L.latLng(point.lat, point.lng));

      var polyline = L.polyline(waypoints, { linecap: 'round', color: '#00007b', stroke: true, weight: 4, lineJoin: 'round', fill: false }).addTo(this.myMap);
      this.myMap.fitBounds(polyline.getBounds());
      let PointsArray = []
      let timeArray = []
      polylinePoints.map((marker) => { PointsArray.push([marker.lng, marker.lat]) })
      polylinePoints.map((marker) => { timeArray.push(Date.parse(marker.timestamp)) })
      console.log(PointsArray)
      const playbackPolylinePoints =
        [{
          type: "Feature",
          geometry: {
            type: "MultiPoint",
            // coordinates: PointsArray,
            coordinates: PointsArray,
          },
          properties: {
            time: timeArray,
          }
        }]
      var playbackOptions = {
        layer: {
          pointToLayer: function (featureData, latlng) {

            var result = {};
            if (featureData && featureData.properties && featureData.properties.path_options) {
              result = featureData.properties.path_options;
            }
            return new L.CircleMarker(latlng, result);
          }
        },
        marker: function () {
          return {
            icon: L.icon({
              iconUrl: './assets/location/person.png',
              iconSize: [40, 40],
              iconAnchor: [16, 32],
              riseOnHover: true,
            }),
          };
        }
      };
      playback = new L.Playback(this.myMap, playbackPolylinePoints, null, playbackOptions);
      control = new L.Playback.Control(playback);
      control.addTo(this.myMap);

      this.map = this.myMap;
      // this.router = router;
    } else {
      this.myMap = L.map('map').setView([this.latestLocation.lat, this.latestLocation.lng], 16);
      var OSM = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 22,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
      OSM.addTo(this.myMap);
      const marker = L.marker([this.latestLocation.lat, this.latestLocation.lng]).addTo(this.myMap);
      marker.setIcon(L.icon({
        iconUrl: './assets/location/person.png',
        iconSize: [40, 40],
        iconAnchor: [16, 32],
        riseOnHover: true,
      }));
      marker.bindPopup('Address :' + this.latestLocation.gps)
    }
  }




  animateMarkerAlongPath(marker, path) {
    let i = 0;
    const animationSpeed = 2000;

    function animate() {
      if (i < path.length - 1) {
        const startPosition = path[i];
        const endPosition = path[i + 1];
        const fraction = i / (path.length - 1);

        const lat = startPosition.lat + fraction * (endPosition.lat - startPosition.lat);
        const lng = startPosition.lng + fraction * (endPosition.lng - startPosition.lng);

        const newPosition = new google.maps.LatLng(lat, lng);
        marker.setPosition(newPosition);
        marker.setIcon('./assets/location/mover.png');
        i++;
        setTimeout(animate, animationSpeed);
      }
    }

    animate();
  }

  refresh() {
    console.log(playback)
    if (playback) {
      $('.lp').remove();
      control.playback.destroy()
    }
    this.getDetails();
  }

  refreshData() {
    this.filter.date = moment(this.today_date).format('YYYY-MM-DD');
    this.getDetails();
    this.selectedDate = this.today_date;
  }


  dateFormat(): void {
    this.filter.date = moment(this.selectedDate).format('YYYY-MM-DD');
    this.getDetails();
    this.AttendanceSummary()
    this.refresh();
    // this.getSlidertracker();
  }



  getBatteryInfo() {
    this.skLoading = true;
    let payLoad = { 'date': this.payload.start_date, 'user_id': this.payload.user_id }

    this.service.post_rqst(payLoad, "Location/dateWiseBatteryConsumption")
      .subscribe((result => {
        if (result['statusCode'] == 200) {
          for (let i = 0; i < result['data'].length; i++) {
            const batteryValue = parseFloat(result['data'][i]['battery']) * 100;
            const roundedBatteryValue = parseFloat(batteryValue.toFixed(2));
            const formattedTime = moment(result['data'][i]['date']).format('h:mm a');
            this.battery.push(roundedBatteryValue);
            this.batteryTime.push(formattedTime);

          }
          this.skLoading = false;
          this.batteryChart = {
            type: "line",
            scaleX: {
              labels: this.batteryTime,
              "step": "86400000",

              "item": {
                "font-size": 9
              },
            },
            "tooltip": {
              "visible": false
            },
            plot: {
              aspect: "spline",
              "tooltip-text": "%t views: %v<br>%k",
              "shadow": 0,
              "line-width": "2px",
              "marker": {
                "type": "circle",
                "size": 3
              }
            },
            plotarea: {
              backgroundColor: 'transparent',
              marginTop: '20px',
            },
            "crosshair-x": {
              "line-color": "#efefef",
              "plot-label": {
                "border-radius": "5px",
                "border-width": "1px",
                "border-color": "#f6f7f8",
                "padding": "10px",
                "font-weight": "bold"
              },
              "scale-label": {
                "font-color": "#000",
                "background-color": "#f6f7f8",
                "border-radius": "5px"
              }
            },
            series: [{
              values: this.battery,
              monotone: true,
              text: "Battey Percent",
              lineColor: '#0071bd',
              "marker": {
                "background-color": "#0071bd",
              },

            },
            ]
          }

        }
        else {
          this.toast.errorToastr(result['statusMsg'])
          this.skLoading = false;
        }
      }))
  }




  moveMarker(map, marker1, latlng) {
    marker1.setPosition(latlng);
    map.panTo(latlng);
  }


  autoRefresh() {
    let i;
    this.route = new google.maps.Polyline({
      path: [],
      geodesic: true,
      strokeColor: '#0B0080',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      editable: false,
      map: this.map
    });

    this.marker1 = new google.maps.Marker({ map: this.map, icon: './assets/location/location.png' });

    for (i = 0; i < this.pathCoords.length; i++) {
      setTimeout((coords: any) => {
        const latlng = new google.maps.LatLng(coords.lat, coords.lng);
        this.route.getPath().push(latlng);
        this.moveMarker(this.map, this.marker1, latlng);
      }, 3000 * i, this.pathCoords[i]);
    }
    // this.startAnimation();
  }





  sliderChanged(event: MatSliderChange) {
    this.sliderValue = event.value;
    const latLng = new google.maps.LatLng(
      this.pathCoords[this.sliderValue].lat,
      this.pathCoords[this.sliderValue].lng
    );
    this.moveMarker(this.map, this.marker1, latLng);
    this.map.panTo(latLng);
  }


  getSlidertracker() {
    setTimeout(() => {
      this.getSlidertrackermain()
    }, 300);
  }

  getSlidertrackermain() {
    const mapProperties = {
      center: new google.maps.LatLng(this.pathCoords[0].lat, this.pathCoords[0].lng),
      zoom: 13, // Adjust the zoom level as needed
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      travelMode: google.maps.TravelMode.TWO_WHEELER,
      provideRouteAlternatives: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);


    const pathCoordinates = this.pathCoords.map(location => new google.maps.LatLng(location.lat, location.lng));

    const path = new google.maps.Polyline({
      path: pathCoordinates,
      geodesic: true,
      strokeColor: '#1a067a',
      strokeOpacity: 5,
      strokeWeight: 5

    });




    path.setMap(this.map);


    this.marker = new google.maps.Marker({
      position: this.pathCoords[0],
      map: this.map,
      animation: google.maps.Animation.DROP // Marker drop animation
    });

    this.marker.setIcon('./assets/location/mover.png');
    this.location_timeanddate = this.pathCoords[0].timestamp
    this.Locationtype = this.pathCoords[0].type
    // this.distance=this.pathCoords[0].distance_from_last
    this.Totaldistance = this.pathCoords[0].total_distance_from_start
    this.Trackerlat = this.pathCoords[0].lat
    this.Trackerlng = this.pathCoords[0].lng


    const startMarker = new google.maps.Marker({
      position: this.pathCoords[0],
      map: this.map
    });
    startMarker.setIcon('./assets/location/start_point.png');


    this.pathCoords.forEach(point => {
      if (point.type == 'Checkin') {
        const checkinMarker = new google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          map: this.map,
          icon: './assets/location/checkin.png'
        });
        checkinMarker.addListener('click', () => {
          this.navigateToCheckinDetailPage(point);
        });
      } else if (point.type == 'Checkout') {
        const checkoutMarker = new google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          map: this.map,
        });
        checkoutMarker.setIcon('./assets/location/point_loc.png');
      }
    });




    const endMarker = new google.maps.Marker({
      position: this.pathCoords[this.pathCoords.length - 1],
      map: this.map
    });
    endMarker.setIcon('./assets/location/end_point.png');

  }

  navigateToCheckinDetailPage(checkinPoint: any) {

    const dialogRef = this.dialog2.open(CheckinViewComponent, {
      panelClass: 'full-width-modal',
      data: {
        'user_id': checkinPoint.user_id,
        'date': checkinPoint.date_created,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });


  }



  onSliderChange(event: any) {
    const locationIndex = event.target.value;

    this.moveMarkerSmoothly(locationIndex);
    this.location_timeanddate = this.pathCoords[locationIndex].timestamp
    this.Locationtype = this.pathCoords[locationIndex].type
    // this.distance=this.pathCoords[locationIndex].distance_from_last
    this.Totaldistance = this.pathCoords[locationIndex].total_distance_from_start
    this.Trackerlat = this.pathCoords[locationIndex].lat
    this.Trackerlng = this.pathCoords[locationIndex].lng



  }

  moveMarkerSmoothly(locationIndex: number) {
    // Clear the previous animation interval
    clearInterval(this.animationInterval);

    const startTime = performance.now();
    const startLocation = this.marker.getPosition();
    const endLocation = new google.maps.LatLng(
      this.pathCoords[locationIndex].lat,
      this.pathCoords[locationIndex].lng
    );

    const duration = 90; // Adjust the animation duration (in milliseconds) as needed

    this.animationInterval = setInterval(() => {
      const elapsedTime = performance.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const newPosition = google.maps.geometry.spherical.interpolate(
        startLocation,
        endLocation,
        progress
      );
      this.marker.setPosition(newPosition);

      if (progress === 1) {
        clearInterval(this.animationInterval);
      }
    }, 10); // Adjust the animation frame rate (in milliseconds) as needed
  }
  // Slider Tracker end

}
