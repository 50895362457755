import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private downloadProgress = new BehaviorSubject<number>(0);
  private downloaderActive = new BehaviorSubject<boolean>(false);
  private totalCount = new BehaviorSubject<number>(0);
  private remainingCount = new BehaviorSubject<number>(0);

  constructor() { }

  getDownloadProgress() {
    return this.downloadProgress.asObservable();
  }

  setDownloadProgress(progress: number) {
    this.downloadProgress.next(progress);
  }

  getDownloaderActive() {
    return this.downloaderActive.asObservable();
  }

  

  setDownloaderActive(active: boolean) {
    this.downloaderActive.next(active);
  }
  setTotalCount(totalCount: number){
    this.totalCount.next(totalCount);
  }
  getTotalCount(){
    return this.totalCount.asObservable();
  }
  setRemainingCount(remainingCount: number){
    this.remainingCount.next(remainingCount);
  }
  getRemainingCount(){
    return this.remainingCount.asObservable();
  }
}
