import { Component, OnInit, Inject } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from 'src/app/dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';



@Component({
  selector: 'app-travel-status-modal',
  templateUrl: './travel-status-modal.component.html'
})
export class TravelStatusModalComponent implements OnInit {
  resetform: any;
  locationAssign: boolean = false;
  arealist: any = [];
  allNetwork: any = [];
  userData: any;
  userId: any;
  userName: any;
  planData: any = {};
  partyList: any = [];
  savingFlag: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    public serve: DatabaseService,
    public alert: DialogComponent,
    public navparams: ActivatedRoute,
    public dialogRef: MatDialogRef<TravelStatusModalComponent>,
    public toast: ToastrManager) {

    this.userData = JSON.parse(localStorage.getItem('st_user'));
    this.userId = this.userData['data']['id'];
    this.userName = this.userData['data']['name'];
    if (this.data.from == 'plan-edit') {
      this.planData.travel_day = this.data.currentDate;

    }
    if (this.data['deleteAreaId'] && this.data['deleteAreaName'] && this.data['from'] == 'location-master') {
      this.locationAssign = true;
      this.area_list();
    }
    else {
      this.locationAssign = false;
    }


  }

  ngOnInit() {
    this.GetAllNetwork()
  }

  update_status() {
    this.serve.post_rqst(this.data, "Travel/changeStatusTravelPlan").subscribe((result) => {

      if (result['statusCode'] == 200) {
        this.toast.successToastr(result['statusMsg']);
        this.dialogRef.close(true);
      }
      else {
        this.toast.errorToastr(result['statusMsg']);
      }
    })
  }
  GetAllNetwork() {
    this.serve.post_rqst('', "Travel/allNetworkModule").subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.allNetwork = result['modules'];
      }
      else {
        this.toast.errorToastr(result['statusMsg']);
      }
    })
  }

  deleteUser() {
    this.alert.delete('User Data !').then((result) => {
      if (result) {
        let value = {};
        this.serve.post_rqst(value, "User/delete_user").subscribe((result) => {
        })
      }
    });
  }


  area_list() {
    this.serve.post_rqst(this.data, "User/area_list").subscribe((response => {
      for (let i = 0; i < response['area_list'].length; i++) {

        if (parseInt(this.data['deleteAreaId']) == parseInt(response['area_list'][i]['id'])) {
        }
        else {
          this.arealist.push(response['area_list'][i]);
        }
      }
    }))

  }

  assign_area() {
    this.serve.post_rqst({ 'id': this.data['deleteAreaId'], 'delarea': this.data['deleteAreaName'], 'updateArea': this.data.area }, "User/delete_area").subscribe((response => {
      if (response['msg'] == 'Deleted') {
        this.alert.success("Location", "Deleted");
        this.dialog.closeAll();
      }
      else {
        this.alert.error("Something went wrong");
        this.dialog.closeAll();
      }
    }))
  }

  getCustomer(type, search) {
    if (type == 'Distributor' || type == 'Channel Partner') {
      type = 1;
    } else if (type == 'Retailer' || type == 'Dealer') {
      type = 3;
    } else if (type == 'Direct Dealer') {
      type = 7;
    }
    this.serve.post_rqst({
      'dr_type': type, 'user_id':
        this.data.user_id, 'search': search
    }, "Travel/getDrList").subscribe((result => {
      if (result['statusCode'] == 200) {
        this.partyList = result['result']
      }
    }))

  }

  checkId(id) {
    var index = this.data.alreadyPlan.findIndex(row => row.dr_id == id)
    if (index != -1) {
      this.alert.error(this.data.alreadyPlan[index]['company_name'] + ' Already Added For ' + this.data.alreadyPlan[index]['date']);
      this.planData.customerId = '';
      return
    }
  }


  submitDetail() {

    if (this.planData.travel_day) {
      this.planData.travel_day = moment(this.planData.travel_day).format('YYYY-MM-DD');
    }

    this.planData.user_id = this.data.user_id;
    this.planData.travel_id = this.data.travel_id;
    this.savingFlag = true;
    this.serve.post_rqst({ 'data': this.planData }, "Travel/editTravelPlan").subscribe((result => {
      if (result['statusCode'] == 200) {
        this.toast.successToastr(result['statusMsg']);
        this.dialogRef.close(true);
      }
      else {
        this.savingFlag = false;
        this.toast.errorToastr(result['statusMsg']);
      }
    }))

  }
}
