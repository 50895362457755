import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { AddGrandMasterBoxComponent } from '../add-grand-master-box/add-grand-master-box.component';
import { DialogComponent } from 'src/app/dialog.component';

@Component({
  selector: 'app-view-master-box-dispatch-detail',
  templateUrl: './view-master-box-dispatch-detail.component.html'
})
export class ViewMasterBoxDispatchDetailComponent implements OnInit {
  loader: boolean = false;
  smallBoxlisting: any = [];
  noScanListing: any = [];
  viewType: any;
  deleteItem: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data, public toast: ToastrManager, public serve: DatabaseService, public dialog1: DialogComponent, public dialogRef: MatDialogRef<ViewMasterBoxDispatchDetailComponent>, public dialog: MatDialog,) {
  }

  ngOnInit() {
    this.viewType = this.data.type
    this.getMasterboxdata()

  }
  getMasterboxdata() {
    this.loader = true;
    this.serve.post_rqst({ 'data': { 'id': this.data.main_data.id, 'type': this.viewType } }, "Dispatch/fetchMasterGrandCouponDetail").subscribe((result) => {

      if (result['statusCode'] == 200) {
        this.loader = false
        this.smallBoxlisting = result['master_grand_coupon'];
        this.noScanListing = result['no_scan_master_grand_coupon'];

      } else {
        this.toast.errorToastr(result['statusMsg']);
      }
    })
  }
  changeMasterbox(data, type) {
    const dialogRef = this.dialog.open(AddGrandMasterBoxComponent, {
      width: '500px',
      data: {
        data,
        type
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMasterboxdata()

    });
  }

  closeDialog() {
    this.dialog.closeAll()
  }

  removeItem(id, order_id, master_box_id) {
    this.dialog1.confirm('Do you want to remove it from ' + this.data.main_data.coupon_code).then((result) => {
      if (result) {
        this.serve.post_rqst({ 'product_id': id, 'order_id': order_id, 'master_box_id': master_box_id }, "Dispatch/deleteNoScanDispatch").subscribe((result => {
          if (result['statusCode'] == 200) {
            this.toast.successToastr(result['statusMsg']);
            this.deleteItem = true;
            this.getMasterboxdata();
          } else {
            this.toast.errorToastr(result['statusMsg']);
          }
        }));
      }
    });
  }

  closeRefresh() {
    this.dialogRef.close(true);
  }

}
