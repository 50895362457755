import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-tab',
  templateUrl: './master-tab.component.html'
})
export class MasterTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
