import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/_services/DatabaseService';
import { MatDialog } from '@angular/material';
import { sessionStorage } from '../localstorage.service';
import { animationFrameScheduler } from 'rxjs';
import { StatusModalComponent } from '../order/status-modal/status-modal.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  count: any = [];
  distactive: boolean = false;
  ordersactive: boolean = false;
  masteractive: boolean = false;
  reportactive: boolean = false;
  loyaltyreportactive: boolean = false;


  leadactive: boolean = false;
  targetactive: boolean = false;
  accountactive: boolean = false;
  schemeactive: boolean = false;
  travelactive: boolean = false;

  login_data: any = {};
  networkType: any = [];
  networkType1: any = [];
  read: any = {};
  nexturl: any;
  tab: 'System Alerts';
  tabType: 'Profile';
  report = [{ 'type': 'PJP-Detail-Report' }, { 'type': 'PJP-Summary-Report' }, { 'type': 'Attendance-Report' }, { 'type': 'Expense-Report' }, { 'type': 'Visit-Detail-Report' }, { 'type': 'Visit-Summary-Report' }, { 'type': 'Deviation-Detail-Report' }, { 'type': 'Deviation-Summary-Report' }]



  constructor(public session: sessionStorage, public route: ActivatedRoute, public serve: DatabaseService, public dialog: MatDialog, private renderer: Renderer2, private router: Router) {
    this.tabType = 'Profile'
    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value;
    this.login_data = this.login_data.data;
    this.serve.count_list();
    this.serve.dr_list();
    this.serve.influencer_netwrk();
  }
  ngOnInit() {
  }

  getNetworkType() {
    this.serve.post_rqst('', "CustomerNetwork/distributionNetworkModule").subscribe((result => {
      this.networkType = result['modules'];
    }))
  }
  getNetworkType1() {
    this.serve.post_rqst('', "Dashboard/leadNetworkModule").subscribe((result => {
      this.networkType1 = result['modules'];
    }))
  }



  status: boolean = false;
  clearFilter() {
    let blank_data = {}
    this.serve.setData(blank_data);
    this.serve.count_list();
    this.serve.currentUserID = ''
  }
  toggleDropdown(value) {
    if (value == 0) {
      this.distactive = false;
      this.ordersactive = false;
      this.leadactive = false;
      this.targetactive = false;
      this.reportactive = false;
      this.loyaltyreportactive = false;

      this.masteractive = false;
      this.accountactive = false;
      this.schemeactive = false;
      this.travelactive = false;
    }
    else if (value == 1) {
      if (this.distactive == false) {
        this.distactive = true;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;

      } else {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false
        this.schemeactive = false;
        this.travelactive = false;
      }

    }

    else if (value == 2) {
      if (this.ordersactive == false) {
        this.distactive = false;
        this.ordersactive = true;
        this.leadactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;

      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 3) {
      if (this.masteractive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = true;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;


        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 9) {
      if (this.targetactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.accountactive = false
        this.leadactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.targetactive = true;
        this.schemeactive = false;
        this.travelactive = false;
      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.targetactive = false;
        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 4) {

      if (this.leadactive == false) {
        this.leadactive = true;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 5) {
      if (this.reportactive == false) {
        this.reportactive = true;
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.schemeactive = false;



      }
      else if(this.loyaltyreportactive == false){
        this.loyaltyreportactive = true;
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.schemeactive = false;

      }
      else {

        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 6) {
      if (this.accountactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.targetactive = false;
        this.accountactive = true;
        this.schemeactive = false;
        this.travelactive = false;

      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.targetactive = false;
        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }
    }
    else if (value == 7) {
      if (this.reportactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.targetactive = false;
        this.reportactive = true;
        this.schemeactive = false;
        this.travelactive = false;

      }


      else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.targetactive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;
      }

    }
    else if (value == 8) {
      if (this.schemeactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.targetactive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.schemeactive = true;
        this.travelactive = false;

      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.targetactive = false;
        this.reportactive = false;
        this.accountactive = false;
        this.schemeactive = false;
        this.travelactive = false;

      }
    }
    else if (value == 10) {
      if (this.travelactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.targetactive = false;
        this.reportactive = false;
        this.schemeactive = false;
        this.travelactive = true;

      } else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.targetactive = false;
        this.reportactive = false;
        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;
      }
    }

    else if (value == 11) {
      if (this.loyaltyreportactive == false) {
        this.distactive = false;
        this.ordersactive = false;
        this.leadactive = false;
        this.masteractive = false;
        this.accountactive = false;
        this.targetactive = false;
        this.reportactive = false;

        this.loyaltyreportactive = true;
        this.schemeactive = false;

      }


      else {
        this.leadactive = false;
        this.ordersactive = false;
        this.distactive = false;
        this.masteractive = false;
        this.targetactive = false;
        this.reportactive = false;

        this.loyaltyreportactive = false;

        this.accountactive = false;
        this.schemeactive = false;


        this.travelactive = false;


      }
      // this.renderer.removeClass(event.target, 'active');
      // this.renderer.removeClass(document.body, 'active');
    }
    else {
      this.distactive = false;
      this.ordersactive = false;
      this.masteractive = false;
      this.leadactive = false;
      this.reportactive = false;
      this.loyaltyreportactive = false;

      this.targetactive = true;
      this.accountactive = false;
      this.schemeactive = false;
      this.travelactive = false;
    }

  }


  toggleHeader() {
    this.status = !this.status;
    if (!this.status) {
      this.renderer.addClass(document.body, 'nav-active');
    }
    else {
      this.renderer.removeClass(document.body, 'nav-active');
    }
  }

  status1: boolean = false;
  toggleNav() {
    this.status1 = !this.status1;
    if (this.status1) {
      this.renderer.addClass(document.body, 'active');
    }
    else {
      this.renderer.removeClass(document.body, 'active');
    }
  }
  gotodmspages(tabtype) {
    this.nexturl = this.route.snapshot.queryParams['returnUrl'] || '/distribution-list/1/Channel%20Partner/distribution-detail/' + this.login_data['id'] + '/' + tabtype;
    this.router.navigate([this.nexturl]);
  }

  openDialog(type): void {
    const dialogRef = this.dialog.open(StatusModalComponent, {
      width: '400px',
      panelClass: 'padding0',
      data: {
        'delivery_from': type,

      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != false) {
      }
    });

  }

}
