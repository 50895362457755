import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { sessionStorage } from 'src/app/localstorage.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { DialogComponent } from 'src/app/dialog.component';



@Component({
    selector: 'app-status-modal',
    templateUrl: './status-modal.component.html'
})
export class StatusModalComponent implements OnInit {
    savingFlag: boolean = false;
    segment: any = {};
    category: any = {};
    login: any = {};
    delivery_from: any;
    userData: any;
    userId: any;
    userName: any;
    salesUser: any = [];
    ActiveTab: any = 'incoming'
    tabType: any = 'stock'
    stockDetails: any = [];
    dr_outDetails: any = [];
    transferRequestData: any = [];
    dr_inDetails: any = [];
    reqProductDetails: any = [];
    stockRequestDetails: any = [];
    sendProductDetails: any = [];
    organisationData: any = [];
    assignDistArray: any = [];
    drlist: any = [];
    tmpOrderStatus: any = '';
    segmentList: any = [];
    dr_data: any = [];
    states: any = [];
    order_item: any = [];
    networkType: any = [];
    warehouse: any = [];
    today_date: any = new Date()
    add_list: any = [];
    networklist: any = [];
    networklist2: any = [];
    productlist: any = [];
    productlist2: any = [];
    tempSearch: any = '';
    stockForm: any = {};

    constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, public dialog1: DialogComponent, public serve: DatabaseService, public session: sessionStorage, public toast: ToastrManager, public dialogRef: MatDialogRef<StatusModalComponent>,) {
        this.userData = JSON.parse(localStorage.getItem('st_user'));
        this.data.checkWareHouse = "No";
        this.segment = this.data.segment;
        this.userId = this.userData['data']['id'];
        this.userName = this.userData['data']['name'];
        this.delivery_from = this.data.delivery_from;
        if (this.data.from == 'primary_order') {
            for (let i = 0; i < this.data.order_item.length; i++) {
                this.order_item.push({ 'id': this.data.order_item[i]['id'], 'product_name': this.data.order_item[i]['product_name'], 'product_code': this.data.order_item[i]['product_code'], 'order_qty': this.data.order_item[i]['qty'], 'qty': this.data.order_item[i]['sale_dispatch_qty'], 'dispatch_qty': parseInt(this.data.order_item[i]['qty']) - parseInt(this.data.order_item[i]['sale_dispatch_qty']) })
            }

        }

        if (this.data.FsCat == true) {
            this.data.organisation_id = '2'
        }

        this.tmpOrderStatus = this.data.order_status;
        if (this.tmpOrderStatus) {
            this.data.order_status = '';
            this.data.reason = '';
        }
    }

    ngOnInit() {
        if (this.delivery_from == 'assignDist') {
            this.distributorList('', this.data.state)
            this.distributorDetail();

        } else if (this.delivery_from == 'assignSales') {
            this.distributorDetail();
            this.getSalesUser('')
        }
        else if (this.delivery_from == 'subcategory-list') {
            this.data.segment_id = this.data.master_category_id.toString();
            this.getSegment();
        }
        else if (this.delivery_from == 'edit_travel_plan_retailer') {
            this.data.employee_id = this.data.employee_id.toString();
            this.data.id = this.data.travel_plan_id.toString();
            this.data.distributor_id = this.data.drId.toString();
            this.allCustomerNetworkList('');
        }
        else if (this.delivery_from == 'add_travel_plan_retailer') {
            this.data.employee_id = this.data.employee_id.toString();
            this.data.id = this.data.travel_plan_id.toString();
            this.allCustomerNetworkList('');

        }
        else if (this.delivery_from == 'month_wise_secondary_sale') {
            this.getStateList();

        }
        else if (this.delivery_from == 'primaryVsSecondaryReport') {
            this.getSalesUser('')
        }
        else if (this.delivery_from == 'ProductWisePrimaryReport') {
            this.getSalesUser('')
        }
        else if (this.data.from == 'request_product_data') {
            this.reqProductDetails = this.data.reqProductData.product_details;
        }
        else if (this.data.from == 'stockRequestDetailsModal') {
            this.stockRequestDetails = this.data.reqProductData;
        }

        else if (this.data.from == 'send_product_data') {
            this.sendProductDetails = this.data.sendProductData.product_details;
        }
        else if (this.data.from == 'stock_product_trans') {
            this.dr_outDetails = this.data.stockProductTrans.dr_out;
            this.dr_inDetails = this.data.stockProductTrans.dr_in;
        }
        else if (this.data.from == 'sendStockToRetailer') {
            this.getNetworkList('');
            this.stockForm.type = this.data.type;
        }
        else if (this.data.from == 'add_new_follow_up') {
            this.getSalesUser('')
        }
        else if (this.data.from == 'approve_transfer_equest') {
            this.transferRequestData = this.data.requestData;
            this.data.requestId = this.transferRequestData.id;
        }

        this.login = JSON.parse(localStorage.getItem('login'));
    }
    reason_reject: any
    primary_order_status_change(reason, status) {
        if (status == 'readyToDispatch') {
            for (let i = 0; i < this.order_item.length; i++) {
                let indexValue = i + 1;
                if (this.order_item[i]['qty'] == 0) {
                    if ((parseInt(this.order_item[i]['dispatch_qty'])) > this.order_item[i]['order_qty']) {
                        this.toast.errorToastr('Row number ' + indexValue + ' remaining QTY. can not be greater than QTY.');
                        return;
                    }
                }
                if (this.order_item[i]['qty'] > 0) {
                    if (parseInt(this.order_item[i]['dispatch_qty']) > parseInt(this.order_item[i]['order_qty']) - parseInt(this.order_item[i]['qty'])) {
                        let value = parseInt(this.order_item[i]['order_qty']) - parseInt(this.order_item[i]['qty'])
                        this.toast.errorToastr('Row number ' + indexValue + ' Dispatch QTY. can not be greater than remaining QTY. ' + value);
                        return;
                    }
                }
            }
            this.dialog1.confirm('You want to update dispatch planned?').then((result) => {
                if (result) {
                    this.savingFlag = true;
                    this.serve.post_rqst({ 'dispatch_item': this.order_item, 'status': status, 'id': this.data.order_id, 'organisation_id': this.data.organisation_id, 'warehouse_id': this.data.warehouse_id, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "dispatch/dispatchOrderCreate").subscribe((result => {
                        if (result['statusCode'] == 200) {
                            this.dialog.closeAll();
                            this.savingFlag = false;
                            this.toast.successToastr(result['statusMsg']);
                        }
                        else {
                            this.savingFlag = false;
                            this.toast.errorToastr(result['statusMsg'])
                        }
                    }))
                }
            });



        }
        else {
            this.savingFlag = true;
            this.serve.post_rqst({ 'reason': reason, 'status': status, 'warehouse_id': this.data.warehouse_id, 'id': this.data.order_id, 'organisation_id': this.data.organisation_id, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Order/primaryOrderStatusChange").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialog.closeAll();
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }

            }))
        }

    }


    selectWarehouse(value) {
        if (value.value == "Yes") {
            this.serve.post_rqst({}, "Dispatch/fetchWarehouse").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.warehouse = result['result'];
                }
                else {
                    this.toast.errorToastr(result['statusMsg']);
                }
            }));

        }
        else {
            this.data.warehouse_id = '';
            this.warehouse = [];
        }

    }
    secondary_order_status_change(reason, status) {
        this.savingFlag = true;
        this.serve.post_rqst({ 'reason': reason, 'status': status, 'id': this.data.order_id, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Order/secondaryOrderStatusChange").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.dialog.closeAll();
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }



    getCompanyData() {
        this.serve.post_rqst({}, "Order/organizationName").subscribe((response => {
            if (response['statusCode'] == 200) {
                this.organisationData = response['result'];
            } else {
                this.toast.errorToastr(response['statusMsg']);
            }

        }));
    }

    distributorList(searcValue, state) {
        this.serve.post_rqst({ 'search': searcValue, 'state': state }, "CustomerNetwork/distributorsList").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.drlist = result['distributors'];

            } else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }))
    }
    distributorDetail() {
        let id = { "id": this.data.drId }
        this.serve.post_rqst(id, "CustomerNetwork/distributorDetail").subscribe((result) => {
            if (result['statusCode'] == 200) {
                this.data = result['distributor_detail'];
                this.data.id = result['distributor_detail']['id'];
                this.data.assigned_sales_user_name = this.data['assigned_sales_user_name'].map(String);
                this.data.distributor_id = this.data['distributors_id'].map(String);
            }
            else {
                this.toast.errorToastr(result['statusMsg'])
            }
        }
        )
    }


    allCustomerNetworkList(searcValue) {
        this.drlist = [];
        this.serve.post_rqst({ 'search': searcValue, 'type': this.data.drType, 'employee_id': this.data.employee_id }, "Travel/drList").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.drlist = result['result'];
            } else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }))
    }


    getSegment() {
        this.serve.post_rqst({}, "Master/getProductCategoryList").subscribe((result => {
            if (result['category_list']['statusCode'] == 200) {
                this.segmentList = result['category_list']['segment_list'];
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['category_list']['statusCode'])
            }
        }))
    }
    UpdateSalesUser() {
        this.savingFlag = true;
        setTimeout(() => {
            this.serve.post_rqst({ 'drId': this.data.id, 'userArray': this.data.assigned_sales_user_name, 'company_name': this.data.company_name }, "CustomerNetwork/drUserAssign").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialog.closeAll();
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }
            }))
        }, 2000);
    }
    // distArray(data){
    //   this.assignDistArray = []
    //   let index = this.drlist.findIndex(row => row.id == data)
    //   if(index != -1){
    //     this.assignDistArray.push(this.drlist[index].id)
    //   }
    // }
    UpdateDistributor() {
        this.savingFlag = true;
        this.serve.post_rqst({ 'drId': this.data.id, 'distributorId': this.data.distributor_id }, "CustomerNetwork/dealerDistributorAssign").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.dialog.closeAll();
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }
    UpdateCustomerNetworkTravel() {
        this.savingFlag = true;
        this.serve.post_rqst({ 'id': this.data.id, 'dr_id': this.data.distributor_id }, "Travel/drChange").subscribe((result => {
            if (result['statusCode'] == 200) {

                this.dialogRef.close(true);
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }


    getDrType(networkData) {
        this.dr_data.push(networkData);
    }

    AddCustomerNetworkTravel() {
        this.savingFlag = true;
        this.serve.post_rqst({ 'id': this.data.id, 'drData': this.dr_data, 'data': this.data }, "Travel/addDr").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.dialogRef.close(true);
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }


    add_segment() {
        this.savingFlag = true;
        this.data.created_by_name = this.userName;
        this.data.created_by_id = this.userId;
        this.serve.post_rqst(this.data, "Master/addCategory").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.toast.successToastr(result['statusMsg']);
                this.savingFlag = false;
                this.dialogRef.close(true)
                this.serve.count_list();
            }
            else {
                this.toast.errorToastr(result['statusMsg']);
                this.savingFlag = false;
            }

        }))

    }
    add_subCategory() {
        this.savingFlag = true;
        this.data.created_by_name = this.userName;
        this.data.created_by_id = this.userId;
        this.serve.post_rqst(this.data, "Master/addSubCategory").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.toast.successToastr(result['statusMsg']);
                this.savingFlag = false;
                this.dialogRef.close(true)
                this.serve.count_list();
            }
            else {
                this.toast.errorToastr(result['statusMsg']);
                this.savingFlag = false;
            }
        }))
    }

    downloadReport() {
        this.savingFlag = true;
        this.data.date_from ? (this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD')) : null;
        this.data.date_to ? (this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD')) : null;
        let apiName = '';
        if (this.delivery_from == 'tavel_plan') {
            apiName = "Reports/TravelPlanReport"
        }
        this.serve.post_rqst({ "date_from": this.data.date_from, "date_to": this.data.date_to }, apiName).subscribe((result: any) => {
            if (result['statusCode'] == 200) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
            }
        }, () => this.savingFlag = false)
    }


    downloadsecondarySaleReport() {
        this.savingFlag = true;
        this.serve.post_rqst({ "filter": this.data }, 'Excel/monthWiseDealerSaleReport').subscribe((result: any) => {
            if (result['msg'] == true) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
            }
        }, () => this.savingFlag = false)
    }


    downloadprimaryvssecondaryReport() {
        this.data.start_date = moment(this.data.start_date).format('YYYY-MM-DD');
        this.data.end_date = moment(this.data.end_date).format('YYYY-MM-DD');
        this.savingFlag = true;
        this.serve.post_rqst({ "filter": this.data }, 'Excel/primaryVsSecondaryReport').subscribe((result: any) => {
            if (result['msg'] == true) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
            }
        }, () => this.savingFlag = false)
    }

    downloadstateWisePrimarySecondaryReport() {
        this.data.start_date = moment(this.data.start_date).format('YYYY-MM-DD');
        this.data.end_date = moment(this.data.end_date).format('YYYY-MM-DD');
        this.savingFlag = true;
        this.serve.post_rqst({ "start_date": this.data.start_date, "end_date": this.data.end_date }, 'Excel/stateWisePrimarySecondaryReport').subscribe((result: any) => {
            if (result['msg'] == true) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
            }
        }, () => this.savingFlag = false)
    }

    downloadorderSummaryReport() {
        this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD');
        this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD');
        this.savingFlag = true;
        this.serve.post_rqst({ filter: { "date_from": this.data.date_from, "date_to": this.data.date_to } }, 'Excel/orderSummary').subscribe((result: any) => {
            if (result['msg'] == true) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
            }
        }, () => this.savingFlag = false)
    }


    downloadproductwisePrimaryReport() {
        this.data.date_from = moment(this.data.start_date).format('YYYY-MM-DD');
        this.data.date_to = moment(this.data.end_date).format('YYYY-MM-DD');
        this.savingFlag = true;
        this.serve.post_rqst({ "search": this.data }, 'Excel/ProductWisePrimaryReport').subscribe((result: any) => {
            if (result['msg'] == true) {
                this.savingFlag = false;
                window.open(this.serve.downloadUrl + result['filename'])
                // return true;
            } else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg']);
            }
        }, () => { this.savingFlag = false; this.toast.errorToastr('Something went wrong'); })
    }


    getStateList() {
        this.serve.post_rqst(0, "Influencer/getAllState").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.states = result['all_state'];
            }
            else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }));
    }

    getAssignDistributorOfSalesman(value) {
        this.serve.post_rqst({ 'userId': this.data.assigned_sales_user_name, 'search': value }, "Reports/distributorAssign").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.assignDistArray = result['result'];
            }
            else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }));
    }


    search_val: any = {}

    arrayFilter() {
        let id = this.order_item.map(e => e.product_name).indexOf(this.search_val.created_by_name);
        return id;
    }


    checkValidation(order_qty, qty, dispatch, index) {

        if (qty == 0) {
            if ((parseInt(dispatch)) > order_qty) {
                this.toast.errorToastr('Row number ' + index + ' dispatch QTY. can not be greater than QTY.');
                return;
            }
        }
        if (qty > 0) {
            if ((dispatch > parseInt(order_qty) - parseInt(qty))) {
                let value = parseInt(order_qty) - parseInt(qty)
                this.toast.errorToastr('Row number ' + index + ' dispatch QTY. can not be greater than remaining QTY. ' + value);
                return;
            }
        }

    }

    requestStatusChange(reason, status) {
        this.savingFlag = true;
        this.serve.post_rqst({ 'reason': reason, 'status': status, 'requestId': this.data.requestId, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Order/").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.dialog.closeAll();
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }


    salesIn: any = [];
    salesOut: any = [];

    getStock() {
        this.serve.post_rqst({ 'id': this.data.stockProductTrans.id, 'product_id': this.data.stockProductTrans.product_id, 'dr_id': this.data.id }, "stock/salesReturnPartyList").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.salesIn = result['return_in'];
                this.salesOut = result['return_out'];

            } else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }))
    }

    getSalesUser(searchValue) {
        this.serve.post_rqst({ 'search': searchValue }, 'Expense/salesUserList').subscribe((response => {
            if (response['statusCode'] == 200) {
                this.salesUser = response['all_sales_user'];

            } else {
                this.toast.errorToastr(response['statusMsg']);
            }
        }));
    }


    getNetworkType() {
        this.serve.post_rqst('', "Enquiry/leadNetworkModule").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.networkType = result['modules'];
            }
            else {
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }


    allDistributorForSelectedExecutive(searcValue) {
        this.drlist = [];
        this.serve.post_rqst({ 'search': searcValue, 'type': this.data.dr_type, 'employee_id': this.data.user_id }, "Followup/drList").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.drlist = result['result'];
            } else {
                this.toast.errorToastr(result['statusMsg']);
            }
        }))
    }

    addNewFollowUp() {
        this.data.next_followup_date = moment(this.data.followup_date).format('YYYY-MM-DD');
        this.savingFlag = true;
        this.serve.post_rqst({ 'data': this.data }, "Followup/addFollowup").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.toast.successToastr(result['statusMsg']);
                this.dialog.closeAll();
                setTimeout(() => {
                    this.savingFlag = false;
                }, 700);
            }
            else {
                this.toast.errorToastr(result['statusMsg']);

            }
        }))

    }

    addIntoToList() {
        let newData
        newData = this.stockForm;
        if (this.add_list.length == 0) {
            this.add_list.push({ 'product': newData.product, 'qty': newData.qty, 'transferPoints': newData.transferPoints });
        }
        else {
            let existIndex = this.add_list.findIndex(row => (row.product.id == this.stockForm['product']['id']));
            if (existIndex != -1) {
                this.toast.errorToastr('Already same product added to items')

                // this.add_list[existIndex].qty=parseInt(this.add_list[existIndex].qty)+parseInt(newData.qty);
                // this.add_list[existIndex].transferPoints = parseInt(this.add_list[existIndex].transferPoints)+parseInt(newData.transferPoints);


            }
            else {
                this.add_list.push({ 'product': newData.product, 'qty': newData.qty, 'transferPoints': newData.transferPoints });
            }

        }
        this.stockForm.product = '';
        this.stockForm.qty = '';
        this.stockForm.transferPoints = '';
    }

    deleteItem(i) {
        this.dialog1.confirm("Delete Item From List?").then((result) => {
            if (result) {
                this.add_list.splice(i, 1);
            }
        })
    }

    searchItems(event, searchType) {
        let item = event.target.value.toLowerCase();
        this.tempSearch = '';
        if (searchType == 'dealer') {
            this.networklist = [];
            for (let x = 0; x < this.networklist2.length; x++) {
                this.tempSearch = this.networklist2[x].name.toLowerCase();
                if (this.tempSearch.includes(item)) {
                    this.networklist.push(this.networklist2[x]);
                }
            }
        }
        else {
            this.productlist = [];
            for (let x = 0; x < this.productlist2.length; x++) {
                this.tempSearch = this.productlist2[x].product_name.toLowerCase();
                if (this.tempSearch.includes(item)) {
                    this.productlist.push(this.productlist2[x]);
                }
            }
        }
    }

    getNetworkList(masterSearch) {

        this.serve.post_rqst({ 'master_search': masterSearch, 'userId': this.userId }, "Stock/assignedCustomer").subscribe((result) => {
            if (result['statusCode'] == 200) {
                this.networklist = result['result'];
                this.networklist2 = result['result'];
            } else {
                this.toast.errorToastr(result['statusMsg'])
            }
        })
    }

    getProductList(masterSearch) {
        this.serve.post_rqst({ 'master_search': masterSearch, 'userId': this.userId }, this.data.type == 'Transfer' ? 'Stock/fetchNonScannedProduct' : 'Stock/fetchProduct').subscribe((result) => {
            if (result['statusCode'] == 200) {
                this.productlist = result['result'];
                this.productlist2 = result['result'];
            } else {
                this.toast.errorToastr(result['statusMsg'])
            }
        })
    }

    getfieldsClear() {
        this.stockForm.qty = '';
        this.stockForm.transferPoints = '';
    }

    saveStockToRetailer() {
        this.savingFlag = true;
        this.stockForm.add_list = this.add_list;
        this.serve.post_rqst({ "data": this.stockForm, 'drId': this.data.drId }, this.stockForm.type == 'Transfer' ? "Stock/sendCustomerPoints" : "Stock/salesReturnRetailer").subscribe((resp) => {
            if (resp['statusCode'] == 200 && resp['statusMsg'] == 'Success') {
                this.toast.successToastr(resp['statusMsg']);
                this.dialogRef.close();
                this.savingFlag = false;
            }
            else {
                this.toast.errorToastr(resp['statusMsg']);
                this.savingFlag = false;
            }
        }, err => {
            this.savingFlag = false;

        })
    }

    getPointCalculation(data) {
        this.stockForm.transferPoints = data.product.dealer_point * data.qty;

        if (this.stockForm.transferPoints == 0) {
            this.stockForm.transferPoints = '';
        }

    }

    stockCheck() {
        if (this.stockForm.qty > this.stockForm.product.current_stock) {
            this.toast.errorToastr('Less Stock!, Current Stock of this product is ' + this.stockForm.product.current_stock)
            this.stockForm.qty = '';
            this.stockForm.transferPoints = '';
        }
    }

    ChangeRequestStatus() {
        this.savingFlag = true;
        this.serve.post_rqst({ 'data': this.data, 'uid': this.userId, 'uname': this.userName }, "Stock/approveRequest").subscribe((result => {
            if (result['statusCode'] == 200 && result['statusMsg'] == 'Success') {
                this.dialog.closeAll();
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))
    }

    updatePop() {

        if (parseFloat(this.data.approved_qty) > parseFloat(this.data.qty)) {
            this.toast.errorToastr('Approved qty can not be greater than QTY.')
            return;
        }


        this.savingFlag = true;
        this.serve.post_rqst({ 'data': this.data, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "PopGift/popStatusChange").subscribe((result => {
            if (result['statusCode'] == 200) {
                this.dialogRef.close(true);
                this.savingFlag = false;
                this.toast.successToastr(result['statusMsg']);
            }
            else {
                this.savingFlag = false;
                this.toast.errorToastr(result['statusMsg'])
            }
        }))

    }


    updateTarget() {
        this.savingFlag = true;

        if (this.data.from == 'target_status' && (this.data.target_type == 'Secondary Sales Projections' || this.data.target_type == 'Stock Sales Projections')) {
            let type = this.data.from == 'target_status_secondary' ? 'secondary' : 'stock';
            this.data.type = type;
            this.serve.post_rqst({ 'data': this.data, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Target/statusSecondaryChangeTarget").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialogRef.close(true);
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }
            }));
        }

        else if (this.data.from == 'target_status_secondary' && this.data.target_type == 'Secondary Visit Projections') {
            this.serve.post_rqst({ 'data': this.data, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Target/visitStatusChangeTarget").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialogRef.close(true);
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }
            }));
        }

        else if (this.data.from == 'target_status_secondary' || this.data.from == 'target_status_stock') {
            let type = this.data.from == 'target_status_secondary' ? 'secondary' : 'stock';
            this.data.type = type;
            this.serve.post_rqst({ 'data': this.data, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Target/secStatusChangeTarget").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialogRef.close(true);
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }
            }));
        }
        else {
            this.serve.post_rqst({ 'data': this.data, 'action_by': this.login.data.id, 'uid': this.userId, 'uname': this.userName }, "Target/statusChangeTarget").subscribe((result => {
                if (result['statusCode'] == 200) {
                    this.dialogRef.close(true);
                    this.savingFlag = false;
                    this.toast.successToastr(result['statusMsg']);
                }
                else {
                    this.savingFlag = false;
                    this.toast.errorToastr(result['statusMsg'])
                }
            }));
        }
    }

}