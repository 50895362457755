import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
//
import {
    MatButtonModule,
    MatCheckboxModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatChipsModule,
    MatSliderModule,
    MatListModule,
    MatProgressBarModule,
    MatBottomSheetModule,

} from '@angular/material';

@NgModule({
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatDividerModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatChipsModule,
        MatSliderModule,
        MatListModule,
        MatProgressBarModule,
        MatBottomSheetModule,
        DragDropModule,
    ],

    exports: [
        MatButtonModule,
        MatCheckboxModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatDividerModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatChipsModule,
        MatSliderModule,
        MatListModule,
        MatProgressBarModule,
        MatBottomSheetModule,
        DragDropModule,
    ],
})
export class MaterialModule { }